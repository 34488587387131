import type { Order, Product, Shop } from '@prisma/client'
import type { SerializeFrom } from '@remix-run/server-runtime'

import invariant from '~/utils/invariant'

const APP_URL =
  typeof process !== 'undefined' ? process.env.APP_URL : window.ENV?.APP_URL
const NODE_ENV =
  typeof process !== 'undefined' ? process.env.NODE_ENV : window.ENV?.NODE_ENV
const IS_STAGING =
  typeof process !== 'undefined'
    ? process.env.IS_STAGING
    : window.ENV?.IS_STAGING

if (!APP_URL) {
  throw new Error('APP_URL is required')
}
export const appUrl = new URL(APP_URL)
export const appHost = appUrl.host

// simple: in all envs the main path is at the root path
export function makeMainPath(path: string): string {
  return `${APP_URL}${path}`
}

// complexe:
// - in development, the shop is at the app URL on a path (/shop/slug/) so we
// must add the wanted path to it
// - in production, the shop is at a subdomain (slug.protocol.space) so we tweak
// the app URL to add the subdomain and use the path from the root
export function makeShopPath(
  shop: string | Shop | SerializeFrom<Shop>,
  path: string
): string {
  const slug = typeof shop === 'string' ? shop : shop.slug
  const shopOwnPath = makeShopOwnPath(shop, path)

  if (NODE_ENV === 'development') {
    return `${APP_URL}${shopOwnPath}`
  }

  const appUrl = new URL(APP_URL ?? '')
  appUrl.hostname = `${slug}.${appUrl.hostname}`
  return `${appUrl.origin}${shopOwnPath}`
}

// in case the caller knows their are calling from this shop, they can use this
// instead of `makeShopPath` to get an absolute path only instead of a full URL
// (useful for forms)
export function makeShopOwnPath(
  shop: string | Shop | SerializeFrom<Shop>,
  path: string
): string {
  const slug = typeof shop === 'string' ? shop : shop.slug

  if (NODE_ENV === 'development') {
    return `/shop/${slug}${path}`
  }

  return path
}

export function getShopSlug(requestUrl: string): string {
  let shopSlug: string | null = null

  if (NODE_ENV === 'development') {
    // Hack: in development the shop slug is in the path rather than the
    // subdomain.
    // We also get the shop slug from the request URL (we known it's always the
    // second path segment) instead of the route params so we call this even if
    // the DataFunctionArgs is not available (e.g. from the OTP lib)
    const splitPath = new URL(requestUrl).pathname.split('/')
    invariant(splitPath[1] === 'shop', 'shop keyword missing in path')
    shopSlug = splitPath[2] ?? null
  } else {
    const currentUrl = new URL(requestUrl)
    shopSlug = currentUrl.host.replace(`.${appHost}`, '')
  }

  invariant(shopSlug, 'shopSlug missing')

  return shopSlug
}

// helps sorting medias into folders on imagekit
export function makeMediaFolderPath(
  shop?: string | Shop | SerializeFrom<Shop>,
  product?: string | Product | SerializeFrom<Product>
) {
  const shopId = typeof shop === 'string' ? shop : shop?.id
  const productId = typeof product === 'string' ? product : product?.id

  const shopPath = shopId ? `shops/${shopId}` : ''
  const productPath = productId ? `products/${productId}` : ''

  let bucket = ''
  if (NODE_ENV === 'development') {
    bucket = 'development'
  } else if (IS_STAGING === 'true') {
    bucket = 'staging'
  }

  return ['media', bucket, shopPath, productPath].join('/')
}

export function makeOrderViewUrl(
  shop: Shop | SerializeFrom<Shop>,
  order?: string | Order | SerializeFrom<Order>
) {
  const orderId = typeof order === 'string' ? order : order?.id
  return makeShopPath(shop, `/my/orders/${orderId}`)
}
